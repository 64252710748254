"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const tools_1 = require("../tools");
const constants_1 = require("../constants");
function useApiData() {
    const [data, setData] = (0, react_1.useState)(undefined);
    const [errors, setErrors] = (0, react_1.useState)([]);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [isError, setIsError] = (0, react_1.useState)(false);
    const [responseTime, setResponseTime] = (0, react_1.useState)(0);
    const setInitialData = () => {
        setIsLoading(true);
        setData(undefined);
        setIsError(false);
        setErrors([]);
        setResponseTime(0);
    };
    /**
     * Fetch the user's profile.
     */
    const fetchData = function (data) {
        return __awaiter(this, void 0, void 0, function* () {
            setInitialData();
            const start = new Date();
            const response = yield fetch(`${constants_1.API_URL}/v2/lookup`, Object.assign(Object.assign({ method: "POST" }, (0, tools_1.FETCH_HEADERS)()), { body: JSON.stringify(Object.assign({ postal_code: data.postal_code, house_number: data.house_number }, (data.lat && data.lon) ? {
                    coordinates: {
                        lat: data.lat,
                        lon: data.lon,
                    }
                } : {})) })).then((res) => res.json());
            setResponseTime((new Date().getMilliseconds()) - start.getMilliseconds());
            if (!(0, tools_1.isEmptyArray)(response.errors)) {
                setIsError(true);
                setErrors(response.errors);
                setIsLoading(false);
                return;
            }
            setData(response.data);
            setIsLoading(false);
        });
    };
    return {
        data,
        errors,
        responseTime,
        isLoading,
        isError,
        fetchData,
    };
}
exports.default = useApiData;
