"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteComponentWrapper = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const hooks_1 = require("@mantine/hooks");
const constants_1 = require("../../constants");
const RouteComponentWrapper = (props) => {
    const { Component, title = "" } = props;
    (0, hooks_1.useDocumentTitle)(`${title === "" ? "" : `${title} — `} ${constants_1.APP_NAME}`);
    return (0, jsx_runtime_1.jsx)(Component, {});
};
exports.RouteComponentWrapper = RouteComponentWrapper;
