"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const core_1 = require("@mantine/core");
const react_router_dom_1 = require("react-router-dom");
const useStyles = (0, core_1.createStyles)((theme) => ({
    root: {
        paddingTop: 40,
        paddingBottom: 40,
    },
    label: {
        textAlign: 'center',
        fontWeight: 900,
        fontSize: 220,
        lineHeight: 1,
        marginBottom: theme.spacing.xl * 1.5,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
        [theme.fn.smallerThan('sm')]: {
            fontSize: 120,
        },
    },
    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        textAlign: 'center',
        fontWeight: 900,
        fontSize: 38,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 32,
        },
    },
    description: {
        maxWidth: 500,
        margin: 'auto',
        marginTop: theme.spacing.xl,
        marginBottom: theme.spacing.xl * 1.5,
    },
}));
function NotFound() {
    const { classes } = useStyles();
    const navigate = (0, react_router_dom_1.useNavigate)();
    return ((0, jsx_runtime_1.jsxs)(core_1.Container, Object.assign({ className: classes.root }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: classes.label }, { children: "404" })), (0, jsx_runtime_1.jsx)(core_1.Title, Object.assign({ className: classes.title }, { children: "Pagina niet gevonden" })), (0, jsx_runtime_1.jsx)(core_1.Text, Object.assign({ color: "dimmed", size: "lg", my: "3rem", align: "center", className: classes.description }, { children: "Het lijkt erop alsof je de weg kwijt bent! Mogelijk heb je een verkeerd adres ingevoerd of is de pagina verplaatst naar een andere locatie." })), (0, jsx_runtime_1.jsx)(core_1.Group, Object.assign({ position: "center" }, { children: (0, jsx_runtime_1.jsx)(core_1.Button, Object.assign({ component: react_router_dom_1.Link, to: "/", variant: "outline" }, { children: "Terug naar de homepagina" })) }))] })));
}
exports.default = NotFound;
