"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavbarContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
exports.NavbarContext = (0, react_1.createContext)({
    opened: false,
    setOpened: () => { }
});
function NavbarProvider(props) {
    const [opened, setOpenedState] = (0, react_1.useState)(false);
    function setOpened(value) {
        setOpenedState(value);
    }
    return ((0, jsx_runtime_1.jsx)(exports.NavbarContext.Provider, Object.assign({ value: { opened, setOpened } }, { children: props.children })));
}
exports.default = NavbarProvider;
