"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FETCH_HEADERS = exports.formatErrors = exports.isEmptyArray = exports.env = void 0;
function env(name, defaultValue = "") {
    return process.env[`MIX_${name}`] || defaultValue;
}
exports.env = env;
function isEmptyArray(input) {
    return Array.isArray(input) && !input.length;
}
exports.isEmptyArray = isEmptyArray;
/**
 * Format errors from backend
 */
function formatErrors(response) {
    var _a;
    if ((_a = response === null || response === void 0 ? void 0 : response.errors) === null || _a === void 0 ? void 0 : _a.stacktrace) {
        delete response.errors.stacktrace;
    }
    let messages = [];
    response.errors && response.message && messages.push(response.message);
    response.errors && Object.values(response.errors).map((errors) => Object.values(errors).map((err) => messages.push(err)));
    response.error && messages.push(response.error);
    return messages;
}
exports.formatErrors = formatErrors;
/**
 * Get the default fetch headers.
 */
const FETCH_HEADERS = () => {
    return {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    };
};
exports.FETCH_HEADERS = FETCH_HEADERS;
