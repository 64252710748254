"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logo = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const core_1 = require("@mantine/core");
const react_router_dom_1 = require("react-router-dom");
function Logo({ colorScheme }) {
    const navigate = (0, react_router_dom_1.useNavigate)();
    return ((0, jsx_runtime_1.jsx)(core_1.Title, Object.assign({ style: { cursor: "pointer" }, onClick: () => navigate("/"), order: 3 }, { children: "Postcode API" })));
}
exports.Logo = Logo;
