"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const core_1 = require("@mantine/core");
const react_router_dom_1 = require("react-router-dom");
const navbar_1 = require("../../providers/navbar");
const useStyles = (0, core_1.createStyles)((theme) => ({
    link: {
        cursor: "pointer",
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
    },
    activeLink: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1],
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1],
        }
    }
}));
function LinksGroup(props) {
    const { icon: Icon, label, link } = props;
    const { setOpened: setNavbarOpened } = (0, react_1.useContext)(navbar_1.NavbarContext);
    const { classes, cx } = useStyles();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const onClick = () => {
        navigate(link);
        setNavbarOpened(false);
    };
    return ((0, jsx_runtime_1.jsx)(core_1.UnstyledButton, Object.assign({ sx: (theme) => ({
            display: 'block',
            width: '100%',
            padding: theme.spacing.xs,
            borderRadius: theme.radius.sm,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
            '&:hover': {
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            },
        }), onClick: () => onClick(), className: cx(classes.link, location.pathname === link && classes.activeLink) }, { children: (0, jsx_runtime_1.jsxs)(core_1.Group, { children: [(0, jsx_runtime_1.jsx)(Icon, { size: 16 }), (0, jsx_runtime_1.jsx)(core_1.Text, { children: label })] }) })));
}
exports.default = LinksGroup;
