"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const tools_1 = require("../tools");
const constants_1 = require("../constants");
function useApiData() {
    const [data, setData] = (0, react_1.useState)(undefined);
    const [errors, setErrors] = (0, react_1.useState)([]);
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [isError, setIsError] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        fetchData();
    }, []);
    /**
     * Fetch the user's profile.
     */
    const fetchData = () => __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(`${constants_1.API_URL}/v1/export`, Object.assign({ method: "GET" }, (0, tools_1.FETCH_HEADERS)())).then((res) => res.json());
        if (Object.keys(response.errors).length > 0) {
            setIsError(true);
            setErrors(response.errors);
            setIsLoading(false);
            return;
        }
        setData(response.data);
        setIsLoading(false);
    });
    return {
        data,
        errors,
        isLoading,
        isError,
    };
}
exports.default = useApiData;
