"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEBOUNCE_TIMEOUT = exports.API_URL = exports.APP_NAME = void 0;
const tools_1 = require("./tools");
/**
 * The application name
 * Used for setting the title
 */
exports.APP_NAME = (0, tools_1.env)("APP_NAME", "Postcode API");
/**
 * The API url
 */
exports.API_URL = (0, tools_1.env)("API_URL", "https://api.postcode-api.xyz/api");
/**
 * Debounce timeout
 * See https://mantine.dev/hooks/use-debounced-value
 */
exports.DEBOUNCE_TIMEOUT = 300;
